@import url('http://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Ubuntu:wght@300;400;500;700&display=swap');
@import "toastr";
/*
font-family: 'Roboto', sans-serif;
font-family: 'Ubuntu', sans-serif;
font-family: 'Public Sans', sans-serif;
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition:all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f1f5f9;
    transition:all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(20, 184, 166, 0.7);
    border-radius: 100vh;
    transition:all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(20, 184, 166, 1);
  }
  
  .scrollbar-0::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .scrollbar-1::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.1);
  }
  .scrollbar-1::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.2);
  }
  .scrollbar-1::-webkit-scrollbar-thumb:hover {
    background: rgba(255,255,255,0.5);
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

*,*:focus{outline:none;}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Public Sans',sans-serif;
}

.testimonial .swiper-slide{opacity: 0.2; transition: all 0.3s ease;}
.testimonial .swiper-slide.swiper-slide-active{opacity: 1;}

.swiper-button-prev:after{ content:'\f053' !important; font-family: 'Font Awesome 6 Pro' !important; color: #64748b !important; font-weight: 300;}
.swiper-button-next:after{ content:'\f054' !important; font-family: 'Font Awesome 6 Pro' !important; color: #64748b !important; font-weight: 300;}

.hero .swiper-button-prev{ left:40px;}
.hero .swiper-button-prev:after{ color: #fff !important; font-size:80px;font-weight: 100;opacity: 0.5;}
.hero .swiper-button-next{ right:40px;}
.hero .swiper-button-next:after{ color: #fff !important; font-size:80px;font-weight: 100;opacity: 0.5;}

.swiper-slide-active .sub-title{animation: bounceInDown 1s;}
.swiper-slide-active .title{animation: fadeInLeftBig 1.5s;}
.swiper-slide-active .text{animation: zoomIn 1s;}

.tox-tinymce{border: 1px solid #e5e7eb !important;}
.tox .tox-editor-header, .tox .tox-menubar, .tox .tox-toolbar-overlord,.tox .tox-toolbar__primary{background: #f8fafc !important;}
.tox .tox-menubar+.tox-toolbar-overlord{border-top: 1px solid #e5e7eb !important;}
.tox .tox-editor-header{border-radius: 0 !important;border-bottom: 1px solid #e5e7eb !important; box-shadow: none !important;}
.tox .tox-tbtn--bespoke{background: #e5e7eb !important;}
.tox .tox-statusbar{border-top: 1px solid #e5e7eb !important;}
.tox .tox-statusbar .tox-statusbar__branding{display: none !important;}
.tox .tox-tbtn:hover, .tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active),.tox .tox-collection--list .tox-collection__item--active, .tox .tox-mbtn--active, .tox .tox-mbtn:focus:not(:disabled){background: #ccfbf1 !important;}
button.action-btn[disabled]{opacity: 0.3;}